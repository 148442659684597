import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';

import { PagedListMapper } from '../mappers/paged-list.mapper';
import { PagedList } from '../../models/list-utilities/paged-list';
import { PagedListDto } from '../mappers/dto/paged-list.dto';
import { CreateAlternateEstimateData, EditAlternateEstimateData, OverviewAlternateEstimate } from '../../models/estimate/alternate-estimate';
import { InfoAboutNewAlternateDto, OverviewAlternateEstimateDto } from '../mappers/dto/estimate/alternate-estimate.dto';
import { AlternateEstimateMapper } from '../mappers/estimate/alternate-estimate.mapper';
import { AppErrorMapper } from '../mappers/errors/app-error.mapper';
import { FetchListOptions } from '../../models/list-utilities/fetch-list-options';
import { Project } from '../../models/project/project';
import { FetchListOptionsParamsMapper } from '../mappers/http-params.mapper';
import { Estimate, EstimateId } from '../../models/estimate/estimate';
import { EstimateMapper } from '../mappers/estimate/estimate.mapper';
import { EstimateDto } from '../mappers/dto/estimate/estimate.dto';

import { AppUrlsConfig } from './app-urls.config';

/** Final Estimate API service. */
@Injectable({
  providedIn: 'root',
})
export class AlternateEstimateApiService {

  private readonly apiUrls = inject(AppUrlsConfig);

  private readonly http = inject(HttpClient);

  private readonly listMapper = inject(PagedListMapper);

  private readonly alternateEstimateMapper = inject(AlternateEstimateMapper);

  private readonly estimateMapper = inject(EstimateMapper);

  private readonly appErrorMapper = inject(AppErrorMapper);

  private readonly paramsMapper = inject(FetchListOptionsParamsMapper);

  /**
   * Get name for new alternate.
   * @param baseEstimateId Base estimate ID.
   */
  public getNewAlternateName(baseEstimateId: Project['id']): Observable<string> {
    return this.http.get<InfoAboutNewAlternateDto>(this.apiUrls.alternateEstimatesApi.getInfoAboutNewAlternate(baseEstimateId)).pipe(
      map(({ name }) => name),
    );
  }

  /**
   * Get an alternate estimate by its ID.
   * @param id ID.
   */
  public getById(id: Estimate['id']): Observable<Estimate> {
    return this.http.get<EstimateDto>(
      this.apiUrls.alternateEstimatesApi.get(id),
    ).pipe(
      map(dto => this.estimateMapper.fromDto(dto)),
    );
  }

  /**
   * Obtain a list of alternate estimates for provided base estimate.
   * @param id Base estimate ID.
   * @param options Fetch options.
   * We do not use project ID as param (we don't pass filter mapper) that we use simple number instead filter object for FetchListOptions.
   */
  public getList(id: Project['id'], options?: FetchListOptions<Project['id']>): Observable<PagedList<OverviewAlternateEstimate>> {
    const params = options ? this.paramsMapper.toDto(options) : undefined;
    return this.http.get<PagedListDto<OverviewAlternateEstimateDto>>(this.apiUrls.alternateEstimatesApi.getList(id), { params }).pipe(
      map(page => this.listMapper.fromDto(
        page,
        { fromDto: this.alternateEstimateMapper.fromOverviewDto.bind(this.alternateEstimateMapper) },
        options?.pagination,
      )),
    );
  }

  /**
   * Create a new alternate estimate.
   * @param id Base estimate ID.
   * @param data Data required to create a new alternate estimate.
   */
  public create(id: EstimateId, data: CreateAlternateEstimateData): Observable<EstimateId> {
    return this.http.post<EstimateDto['id']>(
      this.apiUrls.alternateEstimatesApi.create(id),
      this.alternateEstimateMapper.toCreateDto(data),
    ).pipe(
      this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(
        this.alternateEstimateMapper,
      ),
    );
  }

  /**
   * Delete an alternate estimate by its ID.
   * @param alternateId Alternate ID.
   */
  public deleteById(alternateId: number): Observable<void> {
    return this.http.delete<void>(
      this.apiUrls.alternateEstimatesApi.delete(alternateId),
    );
  }

  /**
   * Edit an existing alternate estimate.
   * @param id Alternate estimate ID.
   * @param data Data required to edit info about alternate estimate.
   */
  public edit(id: EstimateId, data: EditAlternateEstimateData): Observable<void> {
    return this.http.put<void>(
      this.apiUrls.alternateEstimatesApi.edit(id),
      this.alternateEstimateMapper.toEditDto(data),
    ).pipe(
      this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(
        this.alternateEstimateMapper,
      ),
    );
  }

  /**
   * Update alternate estimates order.
   * @param estimates List of alternate estimates to update.
   */
  public updateOrder(estimates: readonly OverviewAlternateEstimate[]): Observable<void> {
    const dtos = estimates.map((item, order) => this.alternateEstimateMapper.toOrderDto(item, order + 1));
    return this.http.post<void>(
      this.apiUrls.alternateEstimatesApi.updateOrder,
      { resourceOrders: dtos },
    );
  }
}
