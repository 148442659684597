import { inject, Injectable } from '@angular/core';

import { CreateAlternateEstimateData, EditAlternateEstimateData, OverviewAlternateEstimate } from '@dartsales/common/core/models/estimate/alternate-estimate';
import { EntityValidationErrors } from '@dartsales/common/core/models/errors/app-error';

import { CreateAlternateEstimateDto, EditAlternateEstimateDto, EditAlternateEstimateOrderDto, OverviewAlternateEstimateDto } from '../dto/estimate/alternate-estimate.dto';
import { IValidationErrorMapper } from '../mappers';
import { MappedValidationErrorDto } from '../dto/validation-error.dto';
import { extractErrorMessage } from '../errors/extract-error-message';
import { DateMapper } from '../date-mapper';
import { BasePricingMapper } from '../pricing/base-pricing.mapper';

/** Alternate estimate mapper. */
@Injectable({
  providedIn: 'root',
})
export class AlternateEstimateMapper implements IValidationErrorMapper<CreateAlternateEstimateDto, CreateAlternateEstimateData> {

  private readonly dateMapper = inject(DateMapper);

  private readonly basePricingMapper = inject(BasePricingMapper);

  /**
   * Map overview alternate estimate from DTO.
   * @param dto DTO.
   */
  public fromOverviewDto(dto: OverviewAlternateEstimateDto): OverviewAlternateEstimate {
    return new OverviewAlternateEstimate({
      ...this.basePricingMapper.fromDto(dto),
      id: dto.id,
      name: dto.name,
      description: dto.description ?? '',
      createdAt: this.dateMapper.fromDto(dto.createdAt),
      updatedAt: this.dateMapper.fromDto(dto.updatedAt),
      order: dto.order,
      tdcAmount: dto.tdcAmount,
    });
  }

  /**
   * Map data for alternate estimate creation to DTO.
   * @param data Creation data.
   */
  public toCreateDto(data: CreateAlternateEstimateData): CreateAlternateEstimateDto {
    return {
      name: data.name,
      description: data.description,
      estimateToMergeId: data.estimateId,
    };
  }

  /**
   * Map data for editing an alternate estimate to DTO.
   * @param data Edit data.
   */
  public toEditDto(data: EditAlternateEstimateData): EditAlternateEstimateDto {
    return {
      name: data.name,
      description: data.description,
    };
  }

  /** @inheritdoc */
  public validationErrorFromDto(
    errorDto: MappedValidationErrorDto<CreateAlternateEstimateDto> | null | undefined,
  ): EntityValidationErrors<CreateAlternateEstimateData> {
    return {
      description: extractErrorMessage(errorDto?.description),
    };
  }

  /**
   * Map alternate estimate to order DTO.
   * @param estimate Alternate estimate.
   * @param order Order number.
   */
  public toOrderDto(estimate: OverviewAlternateEstimate, order: number): EditAlternateEstimateOrderDto {
    return {
      id: estimate.id,
      order,
    };
  }
}
